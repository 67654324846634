import React from 'react'
import visa from '../icons/Visa.svg';
import master from '../icons/mastercard.svg';
import maestro from '../icons/applepay.svg';
import instagram from '../icons/facebook.svg';
import twitter from '../icons/linkedin.svg';
import linkedin from '../icons/instagram.svg';
import facebook from '../icons/twitter.svg';
import amexIcon from '../icons/amex.svg'
// import logo from '../icons/logo.jpeg';



const FooterStamp = () => {
  return (
    <div className='border-t-[1px] mt-6 border-lightborder'>
    <div className='w-[90%] m-auto py-2 flex justify-between items-center h-fit  mobile:flex-col-reverse mobile:gap-5 mobile:py-3'>
        <div id="copyright">© 2023 Bellhopt LLC</div>

        {/* PAYMENT CARDS */}
        <div id="payments" className='flex gap-x-[20px] '>
          <img src={visa} alt="visa card" className=""/>
          <img src={master} alt="master card" className=""/>
          <img src={maestro} alt="maestro card" className=""/>
          <img src={amexIcon} alt="maestro card" className=""/>
        </div>

        {/* SOCIAL ICONS*/}
        <div id="socialIcons" className='flex gap-x-[20px] tablet:hidden'>
          {/* <img src={logo} alt="logo" className=' cursor-pointer'/> */}
          <img src={instagram} alt="instagram" className=' cursor-pointer'/>
          <img src={twitter} alt="twitter" className=' cursor-pointer'/>
          <img src={linkedin} alt="linkedin" className=' cursor-pointer'/>
          <img src={facebook} alt="facebook" className=' cursor-pointer'/>

        </div>
    </div>
    </div>
  )
}

export default FooterStamp