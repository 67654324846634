import React from 'react'

const LoadingHomeComponent = () => {

    return (
        <>
            {
                Array.apply(null, Array(10)).map((x, index) => (
                    <div key={index} className="container w-full h-[270px] flex flex-col">
                        <div className="bg-f5 h-[250px]"></div>
                        <div className="bg-f5 my-2 mt-5 h-3 rounded"></div>
                        <div className="bg-f5 my-2 h-3 rounded w-[80%]"></div>
                        <div className="bg-f5 my-2 h-3"></div>
                    </div>
                ))
            }
        </>
    )
}

export default LoadingHomeComponent