const PRODUCT_CATEGORIES = [
    "Fresh Fruit",
    "Vegetables",
    "Milk",
  
 
    "Water",
    "Sparkling Water",
    "Lactose Free Milk",   
    "Eggs",
    
    "Bacon",
    "Cheese",
    "Fresh Smoothies",
    "Cereal",

    "Granola",
    "Chobani Yogurt",
    "Fresh Berries",

    "Organic Fruit",
    "Organic Eggs",
    "Organic Milk",
 
    
    "Soft Drinks",
    "Oatmeal",
    "Ritz",
    "Goldfish",
  
    "Bread",
    "Peanut Butter",
    "Dave's Killer Bread",
 
    "Boar’s Head Deli Meat",
    "Popcorn",
    "Butter",
 
    "Organic Snacks",
    "Snacks",
    "Kids Snacks",
 
    "Doritos",
    "Oreo",
    "Lay's Potato Chips",
 
    "Haagen Dazs Ice Cream",
    "Ben and Jerry's Ice Cream",
    "Ice Cream Bars",
 
    "Cooking Oil",
    "Condiments",
    "Spices",
 
    "Pancake",
    "Syrup",
    "Breakfast Sausage",
 
    "Charcoal",
    "Paper Plates",
    "Plastic Cutlery",
 
 
    "Meats",
    "Beef",
    "Chicken",
  
    "Orange Juice",
    "Green Tea Bags",
    "Starbucks",
 
 
    "Coffee Creamer",
    "Coffee Beans",
    "Coffee K-Cups",
   

 
    "Liquor",
    "Beer",
    "Red Wine",
  
    "Hard Seltzer",
    "Non-Alcoholic Beer",
    "IPA Beer",
 
 
    "Pretzels",
    "Sabra Hummus",
    "Pita Chips",
 
 
    "Tortilla Chips",
    "Salsa",
    "Guacamole",
 
 
    "Trail Mix",
    "Dried Fruit",
    "Nuts",
 
 
    "Non-Stick Cooking Spray",
    "Aluminum Foil",
    "Cookie Dough",
 
 
    "Krispy Kreme Doughnuts",
    "Donuts and Pastries",
    "Cinnamon Rolls",
 
 
    "Organic Vegetables",
    "Fresh Herbs",
    "Organic Salad",
 
 
    "Frozen Breakfast",
    "Frozen Snacks",
    "Frozen Pizza",
 
 
    "Sports Drink",
    "Protein Bar",
    "Energy Drink Variety Pack",
 
 
    "Baby Diapers",
    "Baby Foods",
    "Baby Wipes",
  
    "Toothbrush",
    "Toothpaste",
    "Shaving Cream",
    "Emergen-C",
    "Pain Relief",
    "Sanitizer Wipes",
   
    "Sunscreen",
    "Insect Repellent",
    "Disposable Razors",
   
    "Flower Bouquet",
    "Party Trays",
    "Napkins",
 
 
    "Puzzle",
    "Lego",
    "Stuffed Animal",
 
 
    "Nerf",
    "Barbie",
    "Hot Wheels",
   
    "Pet Food",
    "Dog Toys",
    "Dog Treats",
 
 
 ];
 
 
 export default PRODUCT_CATEGORIES;