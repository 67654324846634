import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { store } from './store/index'
import { Provider } from 'react-redux'
import ReactGa from 'react-ga4';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

const SendAnalytics = () => {
  ReactGa.initialize(process.env.REACT_APP_GA_TRACKING_ID);
  ReactGa.send({ hitType: 'pageview', page: window.location.pathname });
}

reportWebVitals(SendAnalytics);