import { configureStore } from "@reduxjs/toolkit";

// Guest Slices
import { cartSlice, categoriesBarSlice, guestBlurSlice, packagesSlice, productDetailsSlice, productsSlice, relatedProductsSlice, termSlice,setTotalPrice,multipleCategoryProductsSlice, OrderCompletedImagesSlices, dateTimeSlice, guestLocationIdSlice } from "./slices/guestSlices";


const hostInfoFromLocalStorage = localStorage.getItem("hostInfo")
  ? JSON.parse(localStorage.getItem("hostInfo"))
  : null;

const cartItemsFromStorage = localStorage.getItem("cartItems")
  ? JSON.parse(localStorage.getItem("cartItems"))
  : [];

// const guestPropertyFromStorage = localStorage.getItem("property")
//   ? JSON.parse(localStorage.getItem("property"))
//   : [];

const initialState = {
  host: { hostInfo: hostInfoFromLocalStorage },
  cart: { cartItems: cartItemsFromStorage },
};

export const store = configureStore({
  reducer: {

    products: productsSlice.reducer,
    multipleCategoryProducts: multipleCategoryProductsSlice.reducer,
    productDetails: productDetailsSlice.reducer,
    relatedProducts: relatedProductsSlice.reducer,
    cart: cartSlice.reducer,
    packages: packagesSlice.reducer,
    term: termSlice.reducer,
    orderImagesList: OrderCompletedImagesSlices.reducer,
    dateTime: dateTimeSlice.reducer,

    // categoryBar
    categoryBar: categoriesBarSlice.reducer,
    guestBlur: guestBlurSlice.reducer,
    guestLocationId: guestLocationIdSlice.reducer
  },
  preloadedState: initialState,
});
