import React, { useState, useEffect } from "react";
import rightArrow from "../icons/rightArrow.svg";
import leftArrow from "../icons/leftArrow.svg";
import { useDispatch } from "react-redux";
import { getProducts, getRelatedProducts } from "../store/thunks/guestActions";
import { useSelector } from "react-redux";

function ProductHeading({ name, type, location }) {
  const dispatch = useDispatch();
  const { term } = useSelector((state) => state.term);

  const [start, setStart] = useState(1);

  const handleNextBtn = () => {
    type == "home" && dispatch(getProducts({ term, start: start + 10 }));
    type == "related" &&
      dispatch(getRelatedProducts({ term, start: start + 10 }));
    setStart(start + 10);
  };

  const handlePrevBtn = () => {
    if (start - 10 >= 1) {
      type == "home" && dispatch(getProducts({ term, start: start - 10 }));
      type == "related" &&
        dispatch(getRelatedProducts({ term, start: start - 10 }));
      setStart(start - 10);
    }
  };

  const handleMoreBtn = () => {
    type == "home" && dispatch(getProducts({ term, start, limit: 30 }));
  };

  return (
    <div className="my-4">
      <div className="w-[90%] m-auto tablet:w-[90%] flex justify-between">
        <h1 className="font-bold text-[36px] font-segoe tablet:text-[20px] text-[#253D4E]">
          {location !== "bottom" && name}
        </h1>
        <div className="flex justify-between gap-6">
          <div className="flex justify-between items-center gap-5 pt-[7px]">
            {location !== "bottom" &&
              <>
                <div className="bg-lightgrey w-[40px] h-[40px] rounded-[50%] flex justify-center items-center">
                  <button>
                    <img src={leftArrow} onClick={handlePrevBtn} />
                  </button>
                </div>
                <div className="bg-lightgrey w-[40px] h-[40px] rounded-[50%] flex justify-center items-center">
                  <button>
                    <img src={rightArrow} onClick={handleNextBtn} />
                  </button>
                </div>
              </>
            }
            {type === "home" && (
              <div
                className="text-[red] font-semibold text-[20px] font-segoe tablet:text-[16px] cursor-pointer"
                onClick={handleMoreBtn}
              >
                see more
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductHeading;
