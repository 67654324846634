import React from 'react'
import logo from '../icons/landinglogo.svg'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { updateTerm } from '../store/slices/guestSlices'
import { getProducts } from '../store/thunks/guestActions'
import { Link } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import TermsAndConditions from './TermsAndConditions'
import { useState } from 'react'
import ContactUs from './ContactUs'

const Footer = (props) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);

  const closeTermModal = () => {
    setShowTermsModal(false);
  };
  const closeContactModal = () => {
    setShowContactModal(false);
  };

  return (
    <div className="mt-[50px] w-[90%] m-auto p-2 flex items-center gap-10 justify-between tablet:w-[90%] mobile:flex-col">
      <div id='about' className="w-full mobile:flex flex-col mobile:w-full laptop:max-w-[40%] tabletOnly:max-w-[40%]">

        <TermsAndConditions showModal={showTermsModal} handleCloseModal={closeTermModal}/>
        <ContactUs showModal={showContactModal} handleCloseModal={closeContactModal}/>

        <Link to="/guest">
          <img className="w-[150px] h-[100px]"
            onClick={() => {
              dispatch(updateTerm());
              dispatch(getProducts());
              navigate('/guest');
              window.scrollTo(0, 0);
            }}
            src={logo} />
        </Link>
        <p className='flex-wrap text-[#ADADAD] text-[14px]'>Welcome to our food delivery service for short-term rentals!
        We understand the importance of providing a comfortable and stress-free experience for travelers,
        which is why we offer a unique solution to help stock your fridge with fresh groceries before you arrive.
        With our service, you can skip the hassle of grocery shopping and enjoy the convenience of having a full
        fridge waiting for you upon arrival. Simply browse our selection of high-quality, locally sourced food items,
        and place your order online. We'll take care of the rest, delivering your order directly to your rental
        property before you even step through the door. Our mission is to make your travel experience as enjoyable
        and relaxing as possible, so you can focus on making the most of your time away from home.
        </p>
      </div>


      <div className='flex justify-between w-[60%] mobile:w-[100%] laptop:pl-10 gap-10 mobile:gap-5'>
        <div id='links' className="w-full laptop:pl-5 text-left flex flex-col gap-4">
          {/* <h1 className='font-semibold text-[24px] my-4 text-[#253D4E]'>Links</h1> */}
          {/* <p className='text-[18px] font-segoe text-[#253D4E]'><a href='https://host.bellhopt.com/signup'>Sign up Property</a> </p>
          <p className='text-[18px] font-segoe text-[#253D4E]'>Become a Bellhopt</p> */}
          {/* <NavLink to={'/cart'} onClick={() => window.scrollTo(0, 0)} className={" text-[#253D4E]"}>My Cart</NavLink> */}
          {/* <p className='text-[18px] font-segoe'>My Cart</p> */}
        </div>
        <div id='help' className="w-full laptop:pl-5 text-left flex flex-col gap-4">
          <h1 className='font-semibold text-[24px] my-4 text-[#253D4E]'>Help</h1>
          <p className='text-[18px] font-segoe text-[#253D4E]'><button onClick={()=> setShowContactModal(true)}>Contact Us</button></p>
          <p className='text-[18px] font-segoe text-[#253D4E]'><NavLink to="/privacy">Privacy Policy</NavLink></p>
          <p className='text-[18px] font-segoe text-[#253D4E]' ><button className=' text-left' onClick={()=>setShowTermsModal(true)}>Terms and Conditions</button></p>
        </div>
      </div>
    </div>
  )
}

export default Footer