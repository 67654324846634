import axios from "axios";
import request from "./request";


const getKrogerStoreLocation = async ( lat, lng) => {

  const {data} = await request.get(`/kroger/location?lat=${lat}&lng=${lng}`)


  localStorage.setItem("locationId", JSON.stringify(data.store.locationId));
 
  // storing the Store Location 
  localStorage.setItem("storeLocation", JSON.stringify(`${data.store.address.addressLine1}, ${data.store.address.city}`));
  localStorage.setItem("storeLat", JSON.stringify(data.store.geolocation.latitude));
  localStorage.setItem("storeLng", JSON.stringify(data.store.geolocation.longitude));

  // returning the location Id of Kroger Shop
  return data.store.locationId;
}

const getProductsKroger = async (term , start = 1, limit = 10) => {


  const locationId = localStorage.getItem("locationId") && JSON.parse(localStorage.getItem("locationId"));

  const { data } = await request.get(`/kroger/products?term=${term}&start=${start}&limit=${limit}&locationId=${locationId}`);
  data.data.forEach((item) => {
    // if(item.description.toLowerCase().includes("melon") && item.items[0].soldBy === "WEIGHT") {
    //   item.items[0].soldBy = "UNIT";
    //   item.items[0].price.regular = Number((item.items[0].price.regular * 5).toFixed(2));
    // }
    // else 
    if(item.upc === "0000000003094" && item.items[0].soldBy === "WEIGHT") {
      item.items[0].soldBy = "UNIT";
      let price = Number((item.items[0].price.regular * 2.5).toFixed(2));
      item.items[0].price.regular = price;
    }
    else if(item.upc === "0000000004023" && item.items[0].soldBy === "WEIGHT") {
      item.items[0].soldBy = "UNIT";
      let price = Number((item.items[0].price.regular * 2).toFixed(2));
      item.items[0].price.regular = price;
    }
    else if(item.upc === "0000000003907" && item.items[0].soldBy === "WEIGHT") {
      item.items[0].soldBy = "UNIT";
      let price = Number((item.items[0].price.regular * 2).toFixed(2));
      item.items[0].price.regular = price;
    }
    else if(item.upc === "0000000003093" && item.items[0].soldBy === "WEIGHT") {
      item.items[0].soldBy = "UNIT";
      let price = Number((item.items[0].price.regular * 2.5).toFixed(2));
      item.items[0].price.regular = price;
    }
    else if(item.upc === "0000000004022" && item.items[0].soldBy === "WEIGHT") {
      item.items[0].soldBy = "UNIT";
      let price = Number((item.items[0].price.regular * 2).toFixed(2));
      item.items[0].price.regular = price;
    }
    else if(item.upc === "0000000004056" && item.items[0].soldBy === "WEIGHT") {
      item.items[0].soldBy = "UNIT";
      let price = Number((item.items[0].price.regular * 2).toFixed(2));
      item.items[0].price.regular = price;
    }
    else if(item.upc === "0000000004069" && item.items[0].soldBy === "WEIGHT") {
      item.items[0].soldBy = "UNIT";
      let price = Number((item.items[0].price.regular * 2.5).toFixed(2));
      item.items[0].price.regular = price;
    }
    else if(item.upc === "0000000094664" && item.items[0].soldBy === "WEIGHT") {
      item.items[0].soldBy = "UNIT";
      let price = Number((item.items[0].price.regular * 1.34).toFixed(2));
      item.items[0].price.regular = price;
    }
    else if(item.upc === "0000000094629" && item.items[0].soldBy === "WEIGHT") {
      item.items[0].soldBy = "UNIT";
      let price = Number((item.items[0].price.regular * 2).toFixed(2));
      item.items[0].price.regular = price;
    }
    else if(item.upc === "0000000094554" && item.items[0].soldBy === "WEIGHT") {
      item.items[0].soldBy = "UNIT";
      let price = Number((item.items[0].price.regular * 2.5).toFixed(2));
      item.items[0].price.regular = price;
    }
    else if(item.description.toLowerCase().includes("grape") && item.items[0].soldBy === "WEIGHT") {
      item.items[0].soldBy = "UNIT";
      item.items[0].price.regular = Number((item.items[0].price.regular * 2).toFixed(2));
    }
  })

  return data;
}






const getProductDetailsKroger = async (id) => {

  
  const locationId = localStorage.getItem("locationId") && JSON.parse(localStorage.getItem("locationId"));

  const { data } = await request.get(`/kroger/product?locationId=${locationId}&id=${id}`);
  // if(data.data.description.toLowerCase().includes("melon") && data.data.items[0].soldBy === "WEIGHT") {
  //   data.data.items[0].soldBy = "UNIT";
  //   data.data.items[0].price.regular = Number((data.data.items[0].price.regular * 5).toFixed(2));
  // }
  // else 
  if(data.data.upc === "0000000003094" && data.data.items[0].soldBy === "WEIGHT") {
    data.data.items[0].soldBy = "UNIT";
    let price = Number((data.data.items[0].price.regular * 2.5).toFixed(2));
    data.data.items[0].price.regular = price;
  }
  else if(data.data.upc === "0000000004023" && data.data.items[0].soldBy === "WEIGHT") {
    data.data.items[0].soldBy = "UNIT";
    let price = Number((data.data.items[0].price.regular * 2).toFixed(2));
    data.data.items[0].price.regular = price;
  }
  else if(data.data.upc === "0000000003907" && data.data.items[0].soldBy === "WEIGHT") {
    data.data.items[0].soldBy = "UNIT";
    let price = Number((data.data.items[0].price.regular * 2).toFixed(2));
    data.data.items[0].price.regular = price;
  }
  else if(data.data.upc === "0000000003093" && data.data.items[0].soldBy === "WEIGHT") {
    data.data.items[0].soldBy = "UNIT";
    let price = Number((data.data.items[0].price.regular * 2.5).toFixed(2));
    data.data.items[0].price.regular = price;
  }
  else if(data.data.upc === "0000000004022" && data.data.items[0].soldBy === "WEIGHT") {
    data.data.items[0].soldBy = "UNIT";
    let price = Number((data.data.items[0].price.regular * 2).toFixed(2));
    data.data.items[0].price.regular = price;
  }
  else if(data.data.upc === "0000000004056" && data.data.items[0].soldBy === "WEIGHT") {
    data.data.items[0].soldBy = "UNIT";
    let price = Number((data.data.items[0].price.regular * 2).toFixed(2));
    data.data.items[0].price.regular = price;
  }
  else if(data.data.upc === "0000000004069" && data.data.items[0].soldBy === "WEIGHT") {
    data.data.items[0].soldBy = "UNIT";
    let price = Number((data.data.items[0].price.regular * 2.5).toFixed(2));
    data.data.items[0].price.regular = price;
  }
  else if(data.data.upc === "0000000094664" && data.data.items[0].soldBy === "WEIGHT") {
    data.data.items[0].soldBy = "UNIT";
    let price = Number((data.data.items[0].price.regular * 1.34).toFixed(2));
    data.data.items[0].price.regular = price;
  }
  else if(data.data.upc === "0000000094629" && data.data.items[0].soldBy === "WEIGHT") {
    data.data.items[0].soldBy = "UNIT";
      let price = Number((data.data.items[0].price.regular * 2).toFixed(2));
      data.data.items[0].price.regular = price;
    }
    else if(data.data.upc === "0000000094554" && data.data.items[0].soldBy === "WEIGHT") {
      data.data.items[0].soldBy = "UNIT";
      let price = Number((data.data.items[0].price.regular * 2.5).toFixed(2));
      data.data.items[0].price.regular = price;
    }
  else if(data.data.description.toLowerCase().includes("grape") && data.data.items[0].soldBy === "WEIGHT") {
    data.data.items[0].soldBy = "UNIT";
    data.data.items[0].price.regular = Number((data.data.items[0].price.regular * 2).toFixed(2));
  }
  return data.data;
}

const getGuestProperty = async (pid) => {

  try {
    const { data } = await request.get(`/properties/guest/${pid}`);
    localStorage.setItem("property", JSON.stringify(data));

    // console.log("getGuestProperty", data);
    return data._id;

  } catch (err) {
    console.log(err);
  }
}

export {getProductsKroger, getProductDetailsKroger, getGuestProperty, getKrogerStoreLocation }