import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { addToCart } from "../store/slices/guestSlices";
import { refreshCartMsg } from "../store/slices/guestSlices";
import ReactGa from "react-ga4";

function PackageCard({ products, id, name, description, image }) {

  // console.log("chekcing products; -> ", products)

  const dispatch = useDispatch();


  // changing the items field of kroger to updated Field
  const revisedProducts = products.map((product) => {
    // previously it was product.items[0].price?.regular * 1.2
    // now it is product.items[0].price?.regular for adult beverages
    if(product.categories?.some((category) => category === "Adult Beverage")){
      const RevisedItems = product.items.map((item) => {
        return {
          ...product.items[0],
          price: { regular: product.items[0].price?.regular},
        };
      });
      return { ...product, items: RevisedItems };
    }else {
      const RevisedItems = product.items.map((item) => {
        return {
          ...product.items[0],
          price: { regular: product.items[0].price?.regular * 1.3},
        };
      });
      return { ...product, items: RevisedItems };
    }
  });

  let totalPrice =
    products &&
    revisedProducts &&
    revisedProducts
      .reduce((acc, product) => acc + product.items[0].price?.regular, 0)
      .toFixed(2);

  
  

  // HANDLERS
  const handleAddToCart = () => {
    revisedProducts.forEach((product) => {
      const { productId, images, items, description, brand, categories, size } =
        product;

      const cartItem = {
        productId,
        description,
        images,
        items,
        brand: brand ? brand : "Kroger",
        quantity: 1,
        categories,
        size: size ? size : "",
      };
      dispatch(addToCart(cartItem));
    });
    ReactGa.initialize(process.env.REACT_APP_GA_TRACKING_ID);
    ReactGa.event({
      category: "Packages",
      action: "Added to cart",
      label: id,
    });
    setTimeout(() => {
      dispatch(refreshCartMsg());
    }, 2000);
  };

  const navigate = useNavigate();

  const handleClick = () => {
    const productIds = products.map((product) => product.productId);
    navigate(`/package/${id}`);
    window.scrollTo(0, 0);
  };

  return (
    <div
      className="w-[220px] min-h-[300px] px-3 py-5 border-lightborder border-[1px] flex flex-col justify-between rounded-[20px] cursor-pointer m-auto h-full"
      
    >
      <div onClick={handleClick}>
        <img className="h-[150px] m-auto contained" src={image} />
      </div>
      <h2 className="text-[24px] font-bold font-segoe my-2">{name}</h2>
      <h2 className="text-[20px] font-[700px] font-segoe my-2">${totalPrice}</h2>
      {/* <p className="text-[16px] font-segoe my-2">{description}</p> */}

      <div>
        {" "}
        {products &&
          products.map((prod, index) => (
            <h3 key={index} className="text-base2 font-segoe p-[1px] truncate">
              {prod.description}
            </h3>
          ))}
      </div>
      <div className="flex justify-between items-center mt-5">
        <button
          className="bg-[black] text-[white] px-3 py-1 rounded-[100px]"
          onClick={handleAddToCart}
        >
          Add to cart
        </button>
        {/* <a className='border-b-[1px] border-light cursor-pointer'>Edit items</a> */}
      </div>
    </div>
  );
}

export default PackageCard;
