import React from 'react'

const LoadingProductDetailsImageComponent = () => {
    return (
        <div
            className="w-full flex"
        >
            <div
                className="w-[80%] mobile:w-full m-auto flex flex-col justify-around gap-5"
            >
                <div className="bg-f5 h-[300px] m-auto w-[300px] max-w-[300px] rounded-xl"></div>
                <div className="h-[60px] w-full max-w-[600px] m-auto rounded flex justify-around gap-5 mobile:gap-1">
                    {Array.apply(null, Array(6)).map((x, index) => (

                        <div key={index} className="bg-f5 h-full w-full max-w-[70px]"></div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default LoadingProductDetailsImageComponent