import React from 'react'

const LoadingProductDetailspriceComponent = () => {
    return (

        <div
            className="w-full flex"
        >

            <div className="h-[380px] w-full m-auto rounded flex flex-col justify-around gap-5">
                {
                    Array.apply(null, Array(10)).map((x, index) => (
                        <div key={index} className="bg-f5 h-3 rounded"></div>
                    ))
                }
                <div className='bg-f5 h-10 w-[150px] rounded-[100px]'></div>
            </div>
        </div >
    )
}

export default LoadingProductDetailspriceComponent