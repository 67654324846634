import * as Yup from "yup";

const phoneRegExp = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;


// Extend the checkoutSchema to include userInputAddress validations
const checkoutSchema = Yup.object({
    email: Yup.string().email("Please enter a valid email").lowercase(),
    guestPhone: Yup.string().required("Phone Number is Required").matches(phoneRegExp, "Enter a valid phone number"),
    guestEmail: Yup.string().required("Email is Required in Contact information").email("Please enter a valid email").lowercase(),
    // Add userInputAddress as a nested object schema
    userInputAddress: Yup.object().shape({
      propertyManager: Yup.string(),
      line1: Yup.string(), //.required("Address Line 1 is required"),
      line2: Yup.string(), // Optional
      city: Yup.string(), //.required("City is required"),
      state: Yup.string(), //.required("State is required"),
      zipCode: Yup.string(), //.required("Zip Code is required"),
      entryInstructions: Yup.string(), //.required("Entry Instructions are required")
    }),
  });

const contactSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string().required("Email is Required in Contact information").email("Please enter a valid email").lowercase(),
    phone: Yup.string().required("Phone Number is Required").matches(phoneRegExp, "Enter a valid phone number"),
    message: Yup.string().required("Message cant be Empty")
});

export { checkoutSchema, contactSchema };
