import { createSlice } from "@reduxjs/toolkit";
import {
    getProductDetails,
    getProducts,
    getRelatedProducts,
    getPackages,
    getMultipleCategoryProducts,
    getOrderCompletedImages,
    getGuestLocationId
} from "../thunks/guestActions";
import PRODUCT_CATEGORIES from "../../constants/ProductCategories";


const termSlice = createSlice({
    name: "term",
    initialState: {
        term: "Food",
    },
    reducers: {
        updateTerm: (state, action) => {
            state.term = action?.payload || "Food";
        }
    }
});


const productsSlice = createSlice({
    name: "products",
    initialState: {
        loading: false,
        products: null,
        random: null,
        error: "",
        start: 0,
        limit: 10,
        total: 0,
        activePage: 1,
    },
    reducers: {
        setActivePage: (state, action) => {
            state.activePage = action.payload.page;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProducts.pending, (state) => {
                state.loading = true;
                state.error = ""
            })
            .addCase(getProducts.fulfilled, (state, action) => {
                state.loading = false;
                state.products = action.payload.products;
                state.random = action.payload;
                state.error = ""
                state.total = action.payload.total;
            })
            .addCase(getProducts.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.error;
            });
    },
});

const multipleCategoryProductsSlice = createSlice({
    name: "multipleCategoryProducts",
    initialState: () => {
        const totalContainers = Math.ceil(PRODUCT_CATEGORIES.length / 3);
        const containersMap = [ ...Array(totalContainers).keys()].map( i => i + 1).reduce((acc, containerNumber) => {
            return {
                ...acc,
                [containerNumber]: false
            };
        }, {});
        const productCategoryMap = PRODUCT_CATEGORIES.reduce((acc, productCategory)  => {
            const productProperties = {
                loading: false,
                products: [],
                error: '',
                total: 0,
                load: 0,
                endPage: 0,
                actualPage: 1,
                firstLoadInMobileDone: false
            };
            return {
                ...acc,
                [productCategory]: productProperties
            }
        }, {});

        return { ...productCategoryMap, firstProductsLoaded: false, error: '', containersMap, containerShown: [] };
    },
    reducers: {
        setActualPage: (state, action) => {
            const newValue = action.payload.action === 'next' ? 1 : -1
            state[action.payload.category].actualPage += newValue;
        },
        setFirstLoadInMobileDone: (state, action) => {
            state[action.payload.category].firstLoadInMobileDone = true;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getMultipleCategoryProducts.pending, (state, action) => {
                action.meta.arg.customterms.forEach((category) => {
                    state[category].loading = true;
                    state[category].error =  '';
                });
            })
            .addCase(getMultipleCategoryProducts.fulfilled, (state, action) => {
                action.meta.arg.customterms.forEach((category, index) => {
                    state[category].loading = false;
                    state[category].load = state[category].load + 1;
                    state[category].products = !action.payload.totals ? [...state[category].products, ...action.payload.products] : action.payload.products[index];
                    state[category].total = !action.payload.totals ? state[category].total : action.payload.totals[index];
                    if (action.payload.totals) {
                        state[category].endPage =  state[category].total % 4 === 0
                        ? state[category].total / 4
                        : Math.ceil(state[category].total / 4);
                    }
                });
                state.firstProductsLoaded = true;
            })
            .addCase(getMultipleCategoryProducts.rejected, (state, action) => {
                action.meta.arg.customterms.forEach((category) => {
                    state[category].loading = false;
                    state[category].error = action.payload?.error;
                });
                state.error = action.payload?.error;
            });
    },
});


const productDetailsSlice = createSlice({
    name: "productDetails",
    initialState: {
        loading: false,
        details: {},
        error: "",
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getProductDetails.pending, (state) => {
                state.loading = true;
                state.error = ""
            })
            .addCase(getProductDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.details = action.payload;
            })
            .addCase(getProductDetails.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.error;
            });
    },
});

const relatedProductsSlice = createSlice({
    name: "relatedProducts",
    initialState: {
        loading: false,
        products: null,
        error: "",
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getRelatedProducts.pending, (state) => {
                state.loading = true;
            })
            .addCase(getRelatedProducts.fulfilled, (state, action) => {
                state.loading = false;
                state.products = action.payload;
            })
            .addCase(getRelatedProducts.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.error
            });
    },
});

const guestLocationIdSlice = createSlice({
    name: "guestLocationId",
    initialState: {
        loading: 'initial',
        property: null,
        error: "",
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getGuestLocationId.pending, (state) => {
                state.loading = 'loading';
            })
            .addCase(getGuestLocationId.fulfilled, (state, action) => {
                state.loading = 'ready';
                state.property = action.payload;
            })
            .addCase(getGuestLocationId.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.error
            });
    },
});

const cartSlice = createSlice({
    name: "cart",
    initialState: {
        message: "",
        cartItems: [],
        error: "",
    },
    reducers: {
        clearCart: (state) => {
            state.cartItems = [];
            state.message = "";
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
        },
        addToCart: (state, action) => {
            const itemExist = state.cartItems.some(
                (existingProduct) =>
                    existingProduct.productId === action.payload.productId
            );
            if (!itemExist) {
                // Add substituteOrRefund with a default value when adding a new item
                const newItem = {
                    ...action.payload,
                    substituteOrRefund: "substitute" // default value
                };
                state.cartItems.push(newItem);
                state.message = "Product Added Successfully";
            } else {
                state.cartItems = state.cartItems.map((item) => {
                    if (item.productId === action.payload.productId) {
                        return {
                            ...item,
                            quantity: item.quantity + 1
                            // keep existing substituteOrRefund preference if item already in cart
                        };
                    }
                    return item;
                });
                state.message = "Product Quantity Increased";
            }
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
        },
        removeFromCart: (state, action) => {
            state.cartItems = state.cartItems.filter(
                (item) => item.productId !== action.payload.productId
            );
            state.message = "Product Removed From Cart"
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
        },
        increaseQuantity: (state, action) => {
            state.cartItems = state.cartItems.map((item) => {
                if (item.productId === action.payload) {
                    return {
                        ...item,
                        quantity: item.quantity + 1,
                    };
                }
                return item;
            });
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
        },
        decreaseQuantity: (state, action) => {
            state.cartItems = state.cartItems.map((item) => {
                if (item.productId === action.payload) {
                    return {
                        ...item,
                        quantity: item.quantity - 1,
                    };
                }
                return item;
            });
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
        },
        updateSubstituteOrRefund: (state, action) => {
            state.cartItems = state.cartItems.map((item) => {
                if (item.productId === action.payload.productId) {
                    return {
                        ...item,
                        substituteOrRefund: action.payload.substituteOrRefund,
                    };
                }
                return item;
            });
        },
        refreshCartMsg: (state) => {
            state.message = "";
            state.error = "";
        }
    },
});

const packagesSlice = createSlice({
    name: "packages",
    initialState: {
        loading: false,
        packages: [],
        error: "",
        totalPrice: null,
    },
    reducers: {
        setTotalPrice: (state, action) => {
            state.totalPrice = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPackages.pending, (state) => {
                state.loading = true;
                state.error = ""
            })
            .addCase(getPackages.fulfilled, (state, action) => {
                state.loading = false;
                state.packages = action.payload;
            })
            .addCase(getPackages.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.error
            });
    },
});

const categoriesBarSlice = createSlice({
    name: 'categoryBar',
    initialState: false,
    reducers: {
        setBarOn: state => state = true,
        setBarOff: state => state = false,
    }
})

const guestBlurSlice = createSlice({
    name: 'guestBlur',
    initialState: false,
    reducers: {
        setBlurOn: state => state = true,
        setBlurOff: state => state = false
    }
})

const OrderCompletedImagesSlices = createSlice({
    name: "OrderCompletedImages",
    initialState: {
        loading: false,
        imagesList: [],
        error: "",
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getOrderCompletedImages.pending, (state) => {
                state.loading = true;
            })
            .addCase(getOrderCompletedImages.fulfilled, (state, action) => {
                state.loading = false;
                state.imagesList = action.payload;
            })
            .addCase(getOrderCompletedImages.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.error;
            });
    },
});

const dateTimeSlice = createSlice({
    name: "DateTime",
    initialState: {
        arrivalDate: new Date(),
        arrivalTime: new Date(),
        statusDate: 'ready',
        statusTime: 'ready'
    },
    reducers: {
        setArrivalDate: (state, action) => {
            try {
                state.arrivalDate = action.payload;
                state.statusDate = 'success';
            } catch {
                state.statusDate = 'fail';
            }
            
        },
        setArrivalTime: (state, action) => {
            try {
                state.arrivalTime = action.payload;
                state.statusTime = 'success';
            } catch {
                state.statusTime = 'fail';
            }
        }
    }
});


export const { addToCart, removeFromCart, increaseQuantity, decreaseQuantity, refreshCartMsg, clearCart } = cartSlice.actions;
export const { updateTerm } = termSlice.actions
export const { setBarOn, setBarOff } = categoriesBarSlice.actions
export const { setBlurOn, setBlurOff } = guestBlurSlice.actions
export const { setActivePage } = productsSlice.actions
export const { setArrivalDate, setArrivalTime } = dateTimeSlice.actions;
export const { setActualPage, setFirstLoadInMobileDone  } = multipleCategoryProductsSlice.actions;

export { productsSlice, productDetailsSlice, relatedProductsSlice, cartSlice, packagesSlice, categoriesBarSlice, guestBlurSlice, termSlice, multipleCategoryProductsSlice, OrderCompletedImagesSlices, dateTimeSlice, guestLocationIdSlice };
export const { updateSubstituteOrRefund } = cartSlice.actions;