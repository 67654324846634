import React, { useEffect } from "react";

// import ProductCard from "../components/ProductCard";
import GuestNav from "../components/GuestNav";
import Footer from "../components/Footer";
import ProductHeading from "../components/ProductHeading";
// import ProductDetailsCard from "../components/ProductDetailsCard";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getRelatedProducts } from "../store/thunks/guestActions";
import { Alert } from "@mui/material";
import LoadingHomeComponent from "../components/LoadingHomeComponent";
import PackageDetailsCard from "../components/PackageDetailsCard";
import PackageCard from "../components/PackageCard";


function PackageScreen() {

  const params = useParams();
  const {pid }= params
  // console.log("params",params)
  const dispatch = useDispatch();

  // Use Selectors
  const { loading, products: relatedProducts, error } = useSelector((state) => state.relatedProducts)
  const { message, error: errorCart } = useSelector((state) => state.cart);
  const {packages} = useSelector((state) => state.packages);
  const { term } = useSelector((state) => state.term);
  const {error: errorDetails} = useSelector((state) => state.productDetails);

  const singlePackage = packages.filter((pkg)=> pkg.packageId === pid)

  const isRental = singlePackage[0].name === 'Ebikes';

  // filtering Products if there is no price
  const revisedProducts = relatedProducts && relatedProducts.filter((product) => {
    if (product.items[0].price) {
      return product
    }
  })

  useEffect(() => {

    window.scrollTo(0, 0);
    dispatch(getRelatedProducts({ term }));
  }, [])

  return (
    <div className="w-full h-screen">

      <GuestNav />

      {message && <Alert severity="success" sx={{
        position: "fixed",
        width: "90%",
        margin: "auto",
        top: 10,
        left: 0,
        right: 0,
        zIndex: 10,
      }}>
        {message}
      </Alert>
      }

      {/* Error */}
      {errorCart && <Alert severity="warning" sx={{
        position: "fixed",
        width: "90%",
        margin: "auto",
        top: 10,
        left: 0,
        right: 0,
        zIndex: 10,
      }}>
        {errorCart}
      </Alert>}

      {/* Error state details*/}
      {errorDetails && <Alert severity="warning" sx={{
        position: "fixed",
        width: "90%",
        margin: "auto",
        top: 10,
        left: 0,
        right: 0,
        zIndex: 10,
      }}>
        {errorDetails}
      </Alert>}

      {/* product Details card */}
      <PackageDetailsCard singlePackage={singlePackage} />

      {!isRental && (
        <>
      {/* Related Products */}

      <ProductHeading name={"Related Packages"} type="related" />

      <div className="grid grid-cols-4 w-[80%] m-auto tabletOnly:grid-cols-2 tabletOnly:w-[90%] mobile:grid-cols-1 mobile:w-[90%] gap-y-20">

        {loading ?
          <LoadingHomeComponent />
          : (
            packages.map((pkg) => (
              <PackageCard key={pkg.packageId} products={pkg.products} id={pkg.packageId} name={pkg.name} description={pkg.description} image={pkg.image} />
          ))
          
          )}
      </div>
      </>
    )}
      <Footer />

    </div>
  )
}

export default PackageScreen