import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getProductDetails,
  getRelatedProducts,
} from "../store/thunks/guestActions";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { addToCart, refreshCartMsg } from "../store/slices/guestSlices";
import { Alert } from "@mui/material";
import tick from "../icons/tick.svg";
import LoadingProductDetailsImageComponent from "./LoadingProductDetailsImageComponent";
import LoadingProductDetailsPriceComponent from "./LoadingProductDetailsPriceComponent";
import backIcon from '../icons/uturn.svg'
import SinglePackageCard from "./SinglePackageCard";
import ReactGa from "react-ga4";

function PackageDetailsCard({singlePackage}) {

  console.log("single package", singlePackage)
  const isRental = singlePackage[0].name === 'Ebikes';
  const revisePackages= singlePackage[0].products.map((product)=>{
    // for adult beverages we are not incrementing the price
    if(product.categories?.some((category) => category === "Adult Beverage")){
      const RevisedItems = product.items.map((item) => {
        return {
          ...product.items[0],
          price: { regular: product.items[0].price?.regular},
        };
      });
      return { ...product, items: RevisedItems };
    }else {
      const RevisedItems = product.items.map((item) => {
        return {
          ...product.items[0],
          price: { regular: product.items[0].price?.regular * 1.3 },
        };
      });
      return { ...product, items: RevisedItems };
    }
  })

  const handleAddToCart = () => {
    revisePackages.forEach((product) => {
      const { productId, images, items, description, brand, categories } =
        product;

      const cartItem = {
        productId,
        description,
        images,
        items,
        brand: brand ? brand : "Kroger",
        quantity: 1,
        categories,
      };
      dispatch(addToCart(cartItem));
    });
    ReactGa.initialize(process.env.REACT_APP_GA_TRACKING_ID);
    ReactGa.event({
      category: "Packages",
      action: "Added to cart",
      // label: id,

    });
    setTimeout(() => {
      dispatch(refreshCartMsg());
    }, 2000);
  };





    const packagePrice = revisePackages.reduce((totalPrice, product) => {
      const productPrice = product.items.reduce((subtotal, item) => {
        return subtotal + (item.price?.regular || 0);
      }, 0);
      return totalPrice + productPrice;
    }, 0).toFixed(2);
    
    // console.log(packagePrice);


    // console.log("revisedPackages: ->>>>>>>" ,packagePrice)

    // const revisedProducts = products.map((product) => {
    //   const RevisedItems = product.items.map((item) => {
    //     return {
    //       ...product.items[0],
    //       price: { regular: product.items[0].price?.regular * 1.2 },
    //     };
    //   });
    //   return { ...product, items: RevisedItems };
    // });


  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params= useParams();
  const imageBorderRef = useRef();

  // const { ids } = useParams();
  // const productIds = ids.split(",");
  // console.log('productids', productIds)



  // ----------- USE SELECTORS ---------------
  let {term} = useSelector((state)=> state.term)
  term = term.split("%").join(" ");

  const {
    loading,
    details: productDetails,
  } = useSelector((state) => state.productDetails);


  console.log("productDetails : ", productDetails)

  const { upc, productId, images, items, description, brand, categories, size } =
    productDetails ? productDetails : "";

  //Check Rental
  
  const [image, setImage] = useState(images ? images[0].sizes[1].url : "");
  let revisedPrice = items ? items[0].price?.regular : "";
  if (revisedPrice) {
    if(!(categories?.some((category) => category === "Adult Beverage"))){
      revisedPrice += revisedPrice * parseFloat(process.env.REACT_APP_PRODUCT_INCREMENT_PERCENT);
    }
    revisedPrice = revisedPrice.toFixed(2);
  }
  const soldBy = items ? items[0].soldBy : "";
  const available =
    items && items[0].inventory?.stockLevel === "TEMPORARILY_OUT_OF_STOCK"
      ? false
      : true;

  // changing the items field of kroger to updated Field
  const revisedItems =
    items &&
    items.map((item) => {
      return { ...items[0], price: { regular: revisedPrice } };
    });

  // ----------- HANDLERS ---------------
  const handleAddToCartBtn = () => {
    const cartProduct = {
      productId,
      description,
      images,
      items: revisedItems,
      brand: brand ? brand : "Kroger",
      categories,
      quantity: 1,
      size: size ? size : "",
    };

    dispatch(addToCart(cartProduct));

    setTimeout(() => {
      dispatch(refreshCartMsg());
    }, 2000);
  };

  const handleImageClick = (e, image) => {
    setImage(image);

    const images = document.querySelectorAll(".image-border");
    images.forEach((image) => {
      if (image.classList.contains("active")) {
        image.classList.remove("active");
        image.classList.add("hidden");
      }
    });
    e.target.nextElementSibling.classList.remove("hidden");
    e.target.nextElementSibling.classList.add("active");
  };

  // ----------- USE EFFECT ---------------
  useEffect(() => {
    if (!productDetails) {
      dispatch(getProductDetails(params.id));
    } else {
      if (productDetails.productId !== params.id) {
        dispatch(getProductDetails(params.id));
      } else {
        setImage(images ? images[0].sizes[1].url : "");
      }
    }
  }, [productDetails, params]);


  return (
    <div className="mt-10 pt-20">
        {isRental && (
        <div className="text-center">
          <img
            src="https://bellhopt-public.s3.us-west-1.amazonaws.com/EBNB.png"
            alt="EBNB"
            className="mx-auto"
            style={{ width: '25%' }}
          />
        </div>
      )}
      <div className="w-[80%] m-auto py-2 flex gap-4 items-center">
        <img
          src={backIcon}
          alt="back-icon"
          className="cursor-pointer"
          onClick={() => navigate(-1)}
        />
        <p>Back to Main</p>
      </div>
  
      <div className="flex w-[80%] m-auto justify-between tablet:w-[90%] tablet:flex-col tablet:gap-5">
        <div className="grid grid-cols-3 gap-5 py-7 px-4 justify-between items-center w-[60%] tablet:w-[90%] grid-cols-2 tablet:m-auto border-[1px] border-lightborder mobile:grid-cols-1">
          {singlePackage[0].products.map((product) => (
            <SinglePackageCard product={product} />
          ))}
        </div>
  
        <div className="w-[35%] tablet:w-[90%] tablet:m-auto border-[1px] p-5 border-lightborder min-w-[400px]">
          <div className="border-b-[0.5px] border-lightborder mb-4">
            <div className="pb-5">
              <h1 className="font-bold desktop:text-[32px] laptop:text-[28px] font-segoe mb-5">
                {singlePackage[0].name}
              </h1>
              <p>{singlePackage[0].description}</p>
  
              {!isRental && (
                <>
                  <h1 className="font-bold text-[30px] my-5">
                    Price: ${packagePrice}
                  </h1>
                  <button
                    disabled={!available}
                    onClick={handleAddToCart}
                    className="bg-[#EF4949] py-2 text-white px-5 rounded-[100px]"
                  >
                    {!available ? "Out of Stock" : "Add to Cart"}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
              }
export default PackageDetailsCard;
