import React from 'react'
import FooterStamp from './FooterStamp'
import FooterLast from './FooterLast'

function Footer(props) {
  return (
    <div className='mt-auto'>
    <FooterLast modal={props.modal}/>
    <FooterStamp/>
    </div>
  )
}

export default Footer