import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, refreshCartMsg } from "../store/slices/guestSlices";
import PlusIcon from "../icons/plus-icon.svg";
import MinusIcon from "../icons/minus-icon.svg";
import RENTAL_TYPES from "../constants/RentalTypes";

function SinglePackageCard({ product }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  
  const { pid } = params;
  const { packages } = useSelector((state) => state.packages);
  const singlePackage = packages.filter((pkg) => pkg.packageId === pid);
  const { loading, cartItems, error } = useSelector((state) => state.cart);



  const { productId, images, items, description, brand, categories } = product;
  const image = images[0].sizes[1].url;
  let revisedPrice = items[0].price?.regular;
  const incrementPercent = parseFloat(process.env.REACT_APP_PRODUCT_INCREMENT_PERCENT);
  const available = (items[0]?.inventory?.stockLevel === 'TEMPORARILY_OUT_OF_STOCK' ? false : true);

  if (!categories?.some(category => category === "Adult Beverage")) {
    revisedPrice = (revisedPrice * (1 + incrementPercent)).toFixed(2);
  }
  

  // State for tracking rental duration
  const [rentalDuration, setRentalDuration] = useState(1);
  console.log('Rental Duration:', rentalDuration); // Debugging line

  // Function to handle incrementing the rental duration
  const incrementRentalDuration = (e) => {
  e.stopPropagation(); // Stop event from bubbling up
  setRentalDuration(prev => prev + 1);
  };

  // Function to handle decrementing the rental duration
  const decrementRentalDuration = (e) => {
  e.stopPropagation(); // Stop event from bubbling up
  setRentalDuration(prev => Math.max(1, prev - 1));
  };

  // Check if the product is a rental
  const isRental = RENTAL_TYPES.includes(singlePackage[0].name);

  // Adjusted price for rental duration
  const adjustedPrice = isRental
    ? (revisedPrice * rentalDuration).toFixed(2)
    : revisedPrice.toFixed(2);


// Assuming singlePackage[0].products contains the productIds of e-bikes
const ebikeProductIds = singlePackage[0].products.map(product => product.productId);

// Function to check if any e-bike product in the cart has a different rental duration
const hasEbikeWithDifferentRentalDuration = (cartItems, ebikeProductIds, rentalDuration) => {

  return cartItems.some((cartItem) => 
    ebikeProductIds.includes(cartItem.originalProductId) && 
    cartItem.rentalDuration !== rentalDuration
  );
};


  const handleAddToCartIndividual = (e) => {
    e.stopPropagation(); // Prevent event from propagating to parent elements

    const isEbikesRental = isRental && singlePackage[0].name === 'Ebikes';
    const uniqueIdentifier = isEbikesRental ? `${productId}-${rentalDuration}` : productId;
    const finalPrice = isRental ? revisedPrice * rentalDuration : revisedPrice;
    const modifiedDescription = isEbikesRental
    ? `${description} (${rentalDuration} Day Rental)`
    : description;

 

    if (isEbikesRental) {
      // Check if there's any e-bike in the cart with a different rental duration
      const durationMismatch = hasEbikeWithDifferentRentalDuration(cartItems, ebikeProductIds, rentalDuration);
      if (durationMismatch) {
        alert("You can only add e-bikes with the same rental duration to the cart.");
        return; // Stop the addition process
      }
    }

    const cartProduct = {
      productId: uniqueIdentifier,
      originalProductId: productId,
      description: modifiedDescription,
      images,
      items: [{ ...items[0], price: { regular: finalPrice } }],
      brand: brand ? brand : "Kroger",
      categories,
      quantity: 1,
      rentalDuration: isEbikesRental ? rentalDuration : null,
    };

    dispatch(addToCart(cartProduct));
    setTimeout(() => {
        dispatch(refreshCartMsg());
    }, 2000);
};


  
  return (
<div className="container flex flex-col cursor-pointer max-w-[250px] items-center">
  <div className="relative w-full tablet:w-[100px]">
    <img className="tablet:h-[100px] h-[150px] m-auto" src={image} alt={description} />
  </div>

  <div className="flex flex-col items-center w-full mt-2 tablet:mt-0">
    <h2 className="text-base1 tablet:text-base2 font-bold font-segoe text-center">
      <sup className="text-[10px] tablet:text-[15px]">$</sup>
      {parseInt(adjustedPrice)}
      <sup className="text-[10px] tablet:text-[15px] font-semibold inline-block ml-[1px]">
        {(adjustedPrice.split('.')[1] || '00').padEnd(2, '0')}
      </sup> usd
    </h2>
    {!available && <p className="font-semibold text-base1 text-primary text-center">(Out of Stock)</p>}
    <h3 className="text-base1 tablet:text-base1 font-segoe opacity-95 text-center">{description}</h3>
    <p className="text-base1 text-light font-segoe text-center">(${revisedPrice} / {items[0].soldBy})</p>

    {isRental && (
      <div className="flex items-center justify-center">
        <button onClick={decrementRentalDuration} className="rounded-full cursor-pointer flex justify-center items-center" style={{ backgroundColor: 'black', width: '30px', height: '30px', padding: '5px' }}>
          <img src={MinusIcon} alt="Decrease" style={{ width: '15px', height: '15px' }} />
        </button>
        <label className="text-[20px] px-4 text-black font-segoe">
          {rentalDuration} {rentalDuration === 1 ? (items[0].soldBy === "Hourly" ? "Hour" : "Day") : (items[0].soldBy === "Hourly" ? "Hours" : "Days")}
        </label>
        <button onClick={incrementRentalDuration} className="rounded-full cursor-pointer flex justify-center items-center" style={{ backgroundColor: 'black', width: '30px', height: '30px', padding: '5px' }}>
          <img src={PlusIcon} alt="Increase" style={{ width: '15px', height: '15px' }} />
        </button>
      </div>
    )}

    {available && (
      <button onClick={handleAddToCartIndividual} className="bg-[#EF4949] py-2 text-white px-5 rounded-[100px] mt-2 w-full text-center">
        Add to Cart
      </button>
    )}
  </div>
</div>

  );
  
}

export default SinglePackageCard;
