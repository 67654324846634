import React from 'react'
import logo from '../icons/logo.svg'

export default function RenderLoader() {
  return (
     <div className='h-screen w-full opacity-100 flex justify-center items-center'>
        <img src={logo} className='w-50 h-50 animate-pulse' alt="bellhopt-logo" />
    </div>
  )
}
