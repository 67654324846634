import { getKrogerToken } from '../store/thunks/guestActions';
import { getProductDetailsKroger } from './kroger';
import request from './request';

export const getPackagesKroger = async (packages) => {

    // const { data } = await request.get("/kroger/");
    // const data = await getKrogerToken();
    
    const promises = packages.map(async (p) => {
        return Promise.all(p.products.map(async (prodId) => {
            if(prodId.length === 24) {
                const { data: {data: product} } = await request.get(`/products/${prodId}`);
                return product;
            }else {
                return getProductDetailsKroger(prodId);
            }
        }))
    });
    const temp = await Promise.all(promises);
    for(let i = 0; i < temp.length; i++) {
        temp[i] = {packageId: packages[i]._id, image: packages[i].image, products: temp[i].filter((product) => !(product.items && product.items[0].inventory?.stockLevel === "TEMPORARILY_OUT_OF_STOCK")), name: packages[i].name, description: packages[i].description};
    }
    return temp;
}
