import { createAsyncThunk } from "@reduxjs/toolkit";
import { getProductsKroger, getProductDetailsKroger, getKrogerStoreLocation, getGuestProperty } from "../../utils/kroger";
import request from "../../utils/request";
import { getPackagesKroger } from "../../utils/packages";
import shuffle from 'lodash/shuffle';

const krogerCache = (() => {
    let cache = null;
    let lastUpdated = null;

    return {
        get: () => cache,
        set: (data) => {
            cache = data;
            lastUpdated = Date.now();
        },
        isValid: () => {
            return lastUpdated !== null && Date.now() - lastUpdated < 30 * 60 * 1000; // 30 minutes in milliseconds
        },
    };
})();

export const getKrogerToken = async () => {
    if (krogerCache.isValid()) {
        // if the cache is still valid, return the cached data
        return krogerCache.get();
    } else {
        // otherwise, fetch the access token from /kroger/ and update the cache
        const { data } = await request.get("/kroger/");
        krogerCache.set(data);
        return krogerCache.get();
    }
}

const getProducts = createAsyncThunk(
    "products/getProducts",
    async (params, { rejectWithValue }) => {
        console.log("params: ", params)

        // destructuring function parameters
        const term = params.term ? params.term : 'Food';
        const start = params?.start;
        const limit = params?.limit;

        try {
            // Fetching Access Token From Kroger API using backed route 
            // const data = await getKrogerToken();

            // Fetching property Data From Local Storage
            const property = localStorage.getItem("property") && JSON.parse(localStorage.getItem("property"))

            const { lat, lng } = property.address;

            // Fetching Kroger Store LocationId From Kroger Location API 
            await getKrogerStoreLocation(lat, lng);

            // Fetching Products from the nearby Store
            let products, total;
            if (term) {
                let resp = await getProductsKroger(term, start, limit);
                products = resp.data;
                total = resp.meta?.pagination.total || 0;
            }
            // console.log("products: ", products)

            return { products, total };

        } catch (err) {
            // if (err.request) {
            //     return rejectWithValue({ error: "No response from the server, please try again later" });
            // }
            console.log(err.message);
            return rejectWithValue(err.response.data);
        }
    }
);

const getMultipleCategoryProducts = createAsyncThunk(
    "productDetails/getMultipleCategoryProducts",
    async (params, { rejectWithValue, getState }) => {
        const { customterms, start, end, oneCategory = false } = params;
        try {
            // console.log("terms: ", terms)
            // Fetching Access Token From Kroger API using backed route 
            // const data = await getKrogerToken();

            // Fetching property Data From Local Storage
            const property = localStorage.getItem("property") && JSON.parse(localStorage.getItem("property"))

            const { lat, lng } = property.address;

            // Fetching Kroger Store LocationId From Kroger Location API 
            await getKrogerStoreLocation(lat, lng);
            let products = null;
            let totals = null;
            const promiseToFire = oneCategory ? getProductsKroger(customterms[0], start, end) : Promise.all(customterms.map(category => getProductsKroger(category, start, end)))
            products = await promiseToFire;
            totals = oneCategory ? null : products.map((product) => product.meta?.pagination.total);
            products = oneCategory ? products.data : products.map(product => product.data);
            return { products, totals };
        } catch (err) {
            console.log(err)
            if (err.request) {
                return rejectWithValue({ error: "No response from the server, please try again later" });
            }
            return rejectWithValue(err.response.data);
        }
    }
)

const getProductDetails = createAsyncThunk(
    "productDetails/getProductDetails",
    async (id, { rejectWithValue }) => {

        try {
            // const data = await getKrogerToken()
            if (id.length === 24) {
                const { data: { data: product } } = await request.get(`/products/${id}`);
                return product;
            } else {
                const product = getProductDetailsKroger(id);
                return product;
            }
        } catch (err) {
            if (err.request) {
                return rejectWithValue({ error: "No response from the server, please try again later" });
            }
            return rejectWithValue(err.response.data);
        }
    }
);

const getRelatedProducts = createAsyncThunk(
    "relatedProducts/getRelatedProducts",
    async (params, { rejectWithValue }) => {

        // destructuring function parameters
        const term = params?.term;
        const start = params?.start;

        try {
            // const data = await getKrogerToken()

            let products = await getProductsKroger(term, start);
            products = products.data;

            return products;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

const getPackages = createAsyncThunk(
    "packages/getPackages",
    async (id, { rejectWithValue }) => {

        try {
            // getting saved packages from the DB
            const { data } = await request.get('/packages/active')

            // Passing the product Ids from the packages to kroger in order to fetch details
            const packages = getPackagesKroger(data.packages)

            return packages;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

const getOrderCompletedImages = createAsyncThunk(
    "OrderCompletedImages/getOrderCompletedImages",
    async (id, { rejectWithValue }) => {

        try {

            const { data } = await request.get(`/orders/images/${id}`);
            return data.signedUrls;

        } catch (err) {
            if (err.request) {
                return rejectWithValue({ error: "No response from the server, please try again later" });
            }
            return rejectWithValue(err.response.data);
        }
    }
);

const getGuestLocationId = createAsyncThunk(
    "GuestLocationId/getGuestLocationId",
    async (params, { rejectWithValue }) => {
        try {
            const id = await getGuestProperty(params);
            return id;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);


export { getProducts, getProductDetails, getRelatedProducts, getPackages, getMultipleCategoryProducts, getOrderCompletedImages, getGuestLocationId }